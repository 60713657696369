import { RouterModule, Routes } from '@angular/router';

import { NgModule } from '@angular/core';
import { WebflowStartPage } from './webflow-start.page';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  {
    path: 'uber',
    redirectTo: 'uber/san-francisco',
  },
  {
    path: 'uber/san-francisco',
    component: WebflowStartPage,
    data: {
      webflowVariant: 'uber',
      webflowVariantCity: 'san_francisco'
    },
  },
  {
    path: 'uber/los-angeles',
    component: WebflowStartPage,
    data: {
      webflowVariant: 'uber',
      webflowVariantCity: 'los_angeles'
    },
  },
  {
    path: 'uber/virtual',
    component: WebflowStartPage,
    data: {
      webflowVariant: 'uber_virtual',
    },
  },
  {
    path: 'wash',
    component: WebflowStartPage,
    data: {
      webflowVariant: 'wash',
    },
  },
  {
    path: 'detail',
    component: WebflowStartPage,
    data: {
      webflowVariant: 'detail',
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class WebflowStartPageRoutingModule {}
