import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonInput, Platform } from '@ionic/angular';
import { NavigationService, QueryParamKey } from 'src/app/navigation.service';

import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { BackButtonService } from 'src/app/services/back-button/back-button.service';
import { FlowPage } from '../../flow-director';
import { KeyboardService } from 'src/app/services/keyboard/keyboard.service';
import { LoadingAlertService } from 'src/app/services/loading-alert/loading-alert.service';
import { User } from '@sentry/angular';
import { UserParams } from 'src/app/models/user';
import { UserService } from 'src/app/services/api/user/user.service';
import { finalize } from 'rxjs/operators';

interface WebflowSignUpPageProps {
  onAlreadyHasAccount: () => void;
  addressParams: UserParams;
}

@Component({
  selector: 'ysh-webflow-signup',
  templateUrl: './webflow-signup.page.html',
  styleUrls: ['./webflow-signup.page.scss'],
})
export class WebflowSignUpPage implements OnInit, FlowPage {
  @ViewChild('inputFirstName') inputFirstName: IonInput;
  @ViewChild('inputLastName') inputLastName: IonInput;
  @ViewChild('inputPhone') inputPhone: IonInput;
  @ViewChild('inputEmail') inputEmail: IonInput;
  @ViewChild('inputInviteCode') inputInviteCode: IonInput;

  @Input() onDismiss: () => void;
  @Input() onComplete: (data: User) => void;

  @Input() props: WebflowSignUpPageProps;

  formData: UserParams = {};

  phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  constructor(
    public platform: Platform,
    private userService: UserService,
    private loadingAlertCrtl: LoadingAlertService,
    private analytics: AnalyticsService,
    private keyboardService: KeyboardService,
    private navigation: NavigationService,
    public backButtonService: BackButtonService
  ) {}

  // life cycle hooks
  ngOnInit() {
    this.analytics.trackView('WebflowSignUpPage');
    this.formData.inviteCode = this.navigation.initParams.get(QueryParamKey.InviteCode);
  }

  ionViewWillEnter() {
    this.keyboardService.disableScroll(true);
    setTimeout(() => {
      this.inputFirstName.setFocus();
    }, 600);
  }

  ionViewWillLeave() {
    this.keyboardService.close();
    this.keyboardService.disableScroll(false);
  }

  // Data

  signupUser() {
    this.loadingAlertCrtl.showLoaderWithText('Logging In');
    const params = { ...this.formData, ...this.props.addressParams };
    this.userService
      .signup(params)
      .pipe(
        finalize(() => {
          this.loadingAlertCrtl.dismissLoader();
        })
      )
      .subscribe(
        (data) => {
          this.onComplete?.(data);
        },
        (error) => {
          if (typeof error === 'string' && error.includes('Phone has already been taken')) {
            this.props.onAlreadyHasAccount();
          } else {
            this.loadingAlertCrtl.presentAlert('Error creating account', error);
          }
        }
      );
  }

  formValidates() {
    return this.nameValidates() && this.phoneValidates() && this.emailValidates();
  }

  nameValidates() {
    return this.formData.firstName && this.formData.lastName;
  }

  phoneValidates() {
    return this.formData.phone && this.formData.phone.length === 14;
  }

  emailValidates() {
    const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,63}/;
    return this.formData.email && this.formData.email.match(emailRegex);
  }

  // Actions

  didTapEnter() {
    this.inputLastName.setFocus();
  }

  didTapDone() {
    this.signupUser();
  }

  didTapBack() {
    this.onDismiss?.();
  }

  openLink(link) {
    if (this.platform.is('mobile')) {
      window.open(link, '_system', 'location=yes');
    } else {
      window.open(link);
    }
    return false;
  }
}
