import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalService, YshModals } from 'src/app/services/modal/modal.service';
import { map, takeUntil } from 'rxjs/operators';

import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { Benefit } from 'src/app/models/benefit';
import { MembershipService } from 'src/app/services/api/membership/membership.service';
import { Subject } from 'rxjs';
import { UserService } from 'src/app/services/api/user/user.service';

@Component({
  selector: 'ysh-welcome',
  templateUrl: './welcome.page.html',
  styleUrls: ['./welcome.page.scss'],
})
export class WelcomePage implements OnInit, OnDestroy {
  static PAGE_NAME = YshModals.Welcome;

  flowIndex = 0;
  private unsubscribe: Subject<void> = new Subject();

  constructor(
    public userService: UserService,
    public memberService: MembershipService,
    private analytics: AnalyticsService,
    private modalService: ModalService
  ) {
    this.getUserBenefits();
  }

  // life cycle hooks
  ngOnInit() {
    this.analytics.trackView('WelcomePage');
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  // Data

  getUserBenefits() {
    this.userService.getAppliedBenefits();
    this.userService.benefits$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((benefits) => this.onBenefitsLoad(benefits));
  }

  private onBenefitsLoad(benefits: Benefit[]) {
    if (!benefits || !benefits.length) {
      this.flowIndex = 1;
    }
  }

  // Member Description

  membershipSlideDescription() {
    const sponsoredMessage = `Use Yoshi with no delivery fees and amazing discounts on gas. Your Free Yoshi Membership starts today. Welcome to the club!`;
    const defaultMessage = `Use Yoshi with no delivery fees. Your 30 day free trial starts today! Don’t worry, we will cancel for you at the end if you don’t order service!`;
    return this.memberService.sponsoredFreeMemberBenefit$.pipe(
      map((benefit) => (benefit ? sponsoredMessage : defaultMessage))
    );
  }

  // Actions

  didTapDismiss() {
    this.modalService.dismissModal();
  }

  didTapNext(n: number) {
    this.flowIndex = n;
  }

  membershipBenefitDescription$(benefit: Benefit) {
    return this.memberService.membership$.pipe(
      map((membership) => {
        const isFree = membership && membership.fee === benefit.value;
        return isFree ? `Free Membership!<br>($${benefit.value}/month value)` : benefit.displayName;
      })
    );
  }
}
