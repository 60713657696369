import * as moment from 'moment';

import { BaseEntity, MemoizeGetters, Transform } from './base';

import { Address } from './address';
import { Day } from './day';
import { Route } from './route';
import { ServiceName } from './service-type';
import { toEntity } from './base/transform';

@MemoizeGetters
export class UserAddress extends BaseEntity {
  regularFuelPrice?: string;
  premiumFuelPrice?: string;
  synergyGas?: boolean;
  pricingOption: GasPricingScheme;
  compoundServiceArea: boolean;

  @Transform(toEntity(Route))
  routes: Route[];

  @Transform(toEntity(Address))
  address: Address;

  // Computed Properties

  get days() {
    return this.routes && this.createDays(this.routes);
  }

  get sortedDays() {
    return this.days && this.sortDays(this.days);
  }

  get serviceAvailable(): boolean {
    return this.routes?.length ? true : false;
  }

  getDays(cutoff = 24, service: ServiceName) {
    const routes = this.routes?.filter((route) => route.serviceTypes.includes(service)) || [];
    const days = this.createDays(routes, cutoff);
    return this.sortDays(days);
  }

  getFuelPriceFor(fuelType?: string): number {
    let price = this.regularFuelPrice;
    if (fuelType && fuelType.toLowerCase() === 'premium') {
      price = this.premiumFuelPrice;
    }
    return Number(price);
  }

  // Private

  private sortDays(days: Day[]) {
    return [...days].sort((a, b) => a.nextDate.getTime() - b.nextDate.getTime());
  }

  private createDays(routes: Route[], cutoff = 24): Day[] {
    const days: Day[] = [];
    const isWeekend = (index: number) => [0, 6].includes(index);
    const routesByDate = this.groupRoutesByDate(routes);
    const hasWeekendRoute = routes.find((route) => isWeekend(route.shift.dayIndex));

    for (let i = 0; i <= 6; i++) {
      if (isWeekend(i) && !hasWeekendRoute) {
        continue;
      }
      const isCorrectDay = (routes: Route[]) => routes[0].shift.dayIndex === i;
      const dayRoutes = routesByDate.find(isCorrectDay);
      const date = dayRoutes?.[0].date ?? this.nextDate(i);
      const day = new Day(dayRoutes, date, cutoff);
      days.push(day);
    }
    return days;
  }

  private groupRoutesByDate(routes: Route[]): Route[][] {
    const routesByDate = routes.reduce((map: Record<string, Route[]>, route) => {
      const dateString = route.date.toDateString();
      map[dateString] = map[dateString] ?? [];
      map[dateString].push(route);
      return map;
    }, {});
    return Object.values(routesByDate);
  }

  private nextDate(dayIndex: number) {
    const todayIndex = new Date().getDay();
    const diff = dayIndex >= todayIndex ? dayIndex - todayIndex : dayIndex - todayIndex + 7;
    return moment().add(diff, 'day').toDate();
  }
}

export interface AddressParams {
  locationName?: string | null;
  lat?: number | null;
  lng?: number | null;
  zipCode?: string | null;
  requestSource?: string | null;
}

export enum GasPricingScheme {
  Fixed = 'fixed_regional_price',
  PriceMatch = 'nearest_station_price_match',
}

export function addressParamsForGooglePlaceData(
  data: google.maps.places.PlaceResult
): AddressParams {
  const zipcodeComponent = data.address_components?.find((i) => i.types[0] === 'postal_code');
  const hasName = data.name && !data.formatted_address?.includes(data.name);
  const locationName = hasName ? `${data.name}, ${data.formatted_address}` : data.formatted_address;
  return {
    locationName,
    lat: data.geometry?.location.lat(),
    lng: data.geometry?.location.lng(),
    zipCode: zipcodeComponent?.long_name,
  };
}
