import * as pluralize from 'pluralize';

import { Order, OrderStatus } from './order';

import { ServiceOrder } from './service-order';
import { ServiceSubscription } from './service-subscription';
import { ServiceType } from './service-type';
import { Shift } from './shift';
import { Vehicle } from './vehicle';
import { VehicleSubscription } from './vehicle-subscription';
import moment from 'moment';
import { titleForFrequency } from '../pipes/title-for-frequency/title-for-frequency';

export class Visit {
  vehicle: Vehicle;
  serviceOrders: ServiceOrder[] = [];
  vehicleSubscription: VehicleSubscription;
  serviceSubscriptions: ServiceSubscription[] = [];

  constructor(public order: Order) {
    this.vehicle = order.vehicle;
  }

  static createVisits(
    orders: Order[],
    serviceOrders: ServiceOrder[] = [],
    vehicleSubs: VehicleSubscription[] = [],
    serviceSubs: ServiceSubscription[] = []
  ): Visit[] {
    const visits = orders.map((order) => new Visit(order));
    assignServiceOrdersToVisits(visits, serviceOrders);
    assignVehicleSubscriptionsToVisits(visits, vehicleSubs);
    assignServiceSubscriptionsToVisits(visits, serviceSubs);
    return visits;
  }

  get frequency() {
    if (this.vehicleSubscription) {
      return `Every ${this.vehicleSubscription.frequencyString}`;
    } else {
      return 'One time only';
    }
  }

  frequencyForServiceOrder(order: ServiceOrder) {
    const sub = this.serviceSubscriptions.find((sub) => order.sourceUid === sub.uid);
    return sub?.frequency ? `Every ${titleForFrequency(sub.frequency)}` : `One Time Only`;
  }

  get totalFuelServiceDiscount(): number {
    return this.serviceOrders.reduce((acc, serviceOrder) => {
      const discount = serviceOrder.service.perGallonDiscount || 0;
      return acc + discount;
    }, 0);
  }

  hasServiceForVehicle(serviceType: ServiceType, vehicle: Vehicle): boolean {
    if (this.order.vehicle.uid !== vehicle?.uid) {
      return false;
    }
    const hasOrder = this.order.service.serviceType.name === serviceType.name;
    const hasService = this.serviceOrders.find(
      (order) => order.service.serviceType.name === serviceType.name
    );
    return Boolean(hasOrder || hasService);
  }

  // Timeline

  get timeline(): TimelineItem[] {
    let timeline = [this.initialRouteStatus(this)];
    timeline.push(...this.orderStatus(this));
    timeline.push(this.receiptStatus(this.order));

    // Initial sort by sort time, to ensure items are displayed chronologically
    timeline = timeline.sort((a, b) => {
      if (!a.sortTime || !b.sortTime) {
        return 0;
      }
      if (a.sortTime > b.sortTime) {
        return 1;
      }
      if (a.sortTime < b.sortTime) {
        return -1;
      }
      return 0;
    });

    // Set index to ensuring that completed items come first
    for (const [index, value] of Array.from(timeline.entries())) {
      value.index = value.completed ? index : index * 1000;
    }
    return timeline.sort((a, b) => (a.index || 0) - (b.index || 0));
  }

  get timelineStatus() {
    const defaultStatus = 'Route Starting Soon';
    return this.timeline.find((item) => item.completed)?.statusText || defaultStatus;
  }

  private initialRouteStatus(visit: Visit): TimelineItem {
    return {
      time: this.routeStartedTimeString(visit.order),
      statusText: 'Route Started',
      bundledServices: [],
      address: '',
      completed: this.routeHasStarted(visit.order),
      showBeta: false,
      sortTime: this.sortTimeForRouteStart(visit.order),
    };
  }

  private orderStatus(visit: Visit): TimelineItem[] {
    const bundledServices = visit.serviceOrders.map((order) => order.service.serviceType.title);
    const allComplete = Boolean(
      !visit.serviceOrders.find((order) => order.status !== OrderStatus.Complete)
    );

    return [
      {
        time: '',
        statusText: 'Truck on the Way',
        bundledServices: [],
        address: '',
        completed:
          visit.order.status === OrderStatus.Claimed || visit.order.status === OrderStatus.Complete,
        showBeta: false,
        sortTime: this.sortTimeForOrder(visit.order),
      },
      {
        time: this.timeStringForOrder(visit.order),
        statusText: `${pluralize('Services', visit.serviceOrders.length + 1)} Complete`,
        bundledServices,
        address: visit.order.userAddress.address.locationName,
        completed: visit.order.status === OrderStatus.Complete && allComplete,
        showBeta: this.showBetaTagForOrder(visit.order),
        sortTime: this.sortTimeForOrder(visit.order),
        showRequestedTime: true,
      },
    ];
  }

  private receiptStatus(order: Order): TimelineItem {
    return {
      time: '',
      statusText: 'Receipt Sent',
      bundledServices: [],
      address: '',
      completed: order.receiptSent,
      showBeta: false,
    };
  }

  // helpers

  private routeHasStarted(order: Order) {
    const startTime = Shift.defaultStartTime(order.shiftWindow);
    const startTimeMoment = moment(startTime);
    const now = moment();
    return now.isAfter(startTimeMoment);
  }

  private routeStartedTimeString(order: Order) {
    const startTime = Shift.defaultStartTime(order.shiftWindow);
    const startTimeMoment = moment(startTime);
    return startTimeMoment.format('h:mma');
  }

  private sortTimeForRouteStart(order: Order) {
    const startTime = Shift.defaultStartTime(order.shiftWindow);
    return moment(startTime).toDate();
  }

  private sortTimeForOrder(order: Order) {
    if (order.status === OrderStatus.Complete) {
      return order.timeCompleted;
    } else if (order.eta) {
      return order.eta.etaStart;
    } else if (order.shiftStartTime) {
      return moment(order.shiftStartTime).toDate();
    } else {
      return new Date();
    }
  }

  private timeStringForOrder(order: Order): string {
    if (order.status === OrderStatus.Complete) {
      return moment(order.timeCompleted).format('h:mma');
    }
    if (order.shiftStartTime && !order.eta) {
      return this.requestedWindowForOrder(order);
    }
    if (order.eta) {
      return `ETA: ${order.eta.displayString}`;
    }
    return '';
  }

  private requestedWindowForOrder(order: Order): string {
    if (order && order.shift && order.shiftStartTime && order.shiftEndTime) {
      const start = moment(order.shiftStartTime).format('h:mma');
      const end = moment(order.shiftEndTime).format('h:mma');
      return `${start} - ${end}`;
    }
    return '';
  }

  private showBetaTagForOrder(order: Order): boolean {
    return (
      order &&
      order.eta &&
      order.status !== OrderStatus.Claimed &&
      order.status !== OrderStatus.Complete
    );
  }
}

function assignVehicleSubscriptionsToVisits(visits: Visit[], subscriptions: VehicleSubscription[]) {
  subscriptions.forEach((subscription) => {
    visits
      .filter((visit) => vehicleSubIsBundled(visit.order, subscription))
      .forEach((visit) => {
        visit.vehicleSubscription = subscription;
      });
  });
}

function assignServiceOrdersToVisits(visits: Visit[], serviceOrders: ServiceOrder[]) {
  serviceOrders.forEach((serviceOrder) => {
    const visit = visits.find((visit) => serviceIsBundled(visit.order, serviceOrder));
    if (visit) {
      visit.serviceOrders = [...visit.serviceOrders, serviceOrder];
    }
  });
}

function assignServiceSubscriptionsToVisits(visits: Visit[], subscriptions: ServiceSubscription[]) {
  subscriptions.forEach((subscription) => {
    const visit = visits.find((visit) => serviceSubIsBundled(visit, subscription));
    if (visit) {
      visit.serviceSubscriptions = [...visit.serviceSubscriptions, subscription];
    }
  });
}

function serviceIsBundled(order: Order, serviceOrder: ServiceOrder): boolean {
  const validVehicle = order.vehicle.uid === serviceOrder.vehicle.uid;
  const validDate = moment(serviceOrder.date).isSameOrBefore(order.date);
  const validStatus = serviceOrder.isScheduled || serviceOrder.status === OrderStatus.Complete;
  const validType = !serviceOrder.service.provider;
  const isCompatible = order.service.canAccept(serviceOrder.service);
  return isCompatible && validType && validStatus && validDate && validVehicle;
}

function vehicleSubIsBundled(order: Order, susbcription: VehicleSubscription): boolean {
  const sameVehicle = order.vehicle.uid === susbcription.vehicle.uid;
  const sameService = order.sourceUid === susbcription.uid;
  return sameVehicle && sameService && order.isFromSubscription;
}

function serviceSubIsBundled(visit: Visit, susbcription: ServiceSubscription): boolean {
  const sameVehicle = visit.order.vehicle.uid === susbcription.vehicle.uid;
  const matchingOrder = visit.serviceOrders.find((serviceOrder) => {
    return serviceOrder.sourceUid === susbcription.uid;
  });
  return Boolean(matchingOrder && sameVehicle);
}

export interface TimelineItem {
  index?: number;
  time: string;
  statusText: string;
  bundledServices: string[];
  address: string;
  completed: boolean;
  showBeta: boolean;
  sortTime?: Date;
  showRequestedTime?: boolean;
}
