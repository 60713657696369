import { Component, Input, OnInit } from '@angular/core';

import { Faq } from '../../../../models/faq';

@Component({
  selector: 'ysh-faq',
  templateUrl: './faq.html',
  styleUrls: ['./faq.scss'],
})
export class FaqComponent implements OnInit {
  @Input() frequentlyAskedQuestions: Faq[];
  expandedEleMap = {};
  constructor() {}

  ngOnInit() {
    this.frequentlyAskedQuestions.forEach((q, index) => {
      this.expandedEleMap[index] = false;
    });
  }
}
