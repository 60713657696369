import { ServiceName } from 'src/app/models/service-type';
import { uberCities } from './webflow-start.variants.cities';

export interface WebflowVariant {
  serviceNameSearch: string[];
  inviteCode?: string;
  introTextPromo?: string;
  introText: string;
  imgIntroSrc: string;
  imgMainSrc: string;
  footerCTA: string;
  pricingAndAvailability?: string;
  headerText?: string;
  headerCTA?: string;
  headerImgSrc?: string;
  valuePropText?: string;
  valuePropList?: string[];
  layoutAlt?: boolean;
  faqServiceName?: ServiceName;
  virtual: boolean;
}

export const getWebflowVariant = (key: string, city?: any) => {
  const webflowVariants: { [key: string]: WebflowVariant } = {
    uber: {
      serviceNameSearch: ['rideshare'],
      introText:
        '<b>Uber and Yoshi have partnered to help streamline vehicle inspections.</b> Get a certified inspection, provided by Yoshi, right at your parked vehicle.',
      imgIntroSrc: '/assets/images/webflow/webflow-start-intro-uber.png',
      imgMainSrc: '/assets/images/webflow/webflow-start-main-inspection.png',
      footerCTA: 'Enter the address for your vehicle inspection.',
      pricingAndAvailability: `Available ${uberCities[city]?.serviceTimeWindow} ${uberCities[city]?.serviceDaysWindow}<br>Service is ${uberCities[city]?.servicePrice}`,
      headerImgSrc: '/assets/images/webflow/webflow-start-logos-uber.png',
      headerText: 'Get certified vehicle inspections at home',
      headerCTA: 'Search your address for availability',
      valuePropText:
        '<b>Uber and Yoshi have partnered to help streamline vehicle inspections.</b> Experience the benefits of Yoshi inspections at home:',
      valuePropList: [
        'Maximum convenience ',
        'Same day appointments in most cases',
        `${uberCities[city]?.serviceTimeWindow} availability, ${uberCities[city]?.serviceDaysWindow}`,
        `${uberCities[city]?.servicePrice} for each inspection`,
      ],
      layoutAlt: true,
      faqServiceName: ServiceName.Inspection,
      virtual: false,
    },
    uber_virtual: {
      serviceNameSearch: ['virtual'],
      introText:
        '<b>Uber and Yoshi have partnered to help streamline vehicle inspections.</b> Get a certified inspection, provided by Yoshi, over video chat',
      imgIntroSrc: '/assets/images/webflow/webflow-start-intro-uber.png',
      imgMainSrc: '/assets/images/webflow/webflow-start-main-virtual.png',
      footerCTA: 'Enter zip code.',
      pricingAndAvailability: 'Available 12pm-8pm daily except Sundays<br>Service is $20',
      headerImgSrc: '/assets/images/webflow/webflow-start-logos-uber.png',
      headerText: 'Get a certified virtual vehicle inspection',
      headerCTA: 'Search your zip code for availability',
      valuePropText:
        '<b>Uber and Yoshi have partnered to help streamline vehicle inspections.</b> Experience the benefits of Yoshi virtual inspections:',
      valuePropList: [
        'Maximum convenience ',
        'Same day appointments in most cases',
        '12pm-8pm availability daily (except Sundays)',
        '$20 for each virtual inspection',
      ],
      layoutAlt: true,
      faqServiceName: ServiceName.Inspection,
      virtual: true,
    },
    wash: {
      serviceNameSearch: ['wash', 'detail'],
      inviteCode: 'wash50off',
      introTextPromo:
        '<b>Schedule today and get 50% OFF your first car wash!</b> Yoshi delivers car washes at your home or office so you can save time and money.',
      introText:
        '<b>Schedule a quality car wash today!</b> Yoshi delivers car washes and details at your home or office so you can save time and money.',
      imgIntroSrc: '/assets/images/webflow/webflow-start-intro-wash.png',
      imgMainSrc: '/assets/images/webflow/webflow-start-main-wash.png',
      footerCTA:
        'Have a fleet of 5+ vehicles? <a href="https://www.startyoshi.com/fleet" target="_top">Get a quote</a>',
      virtual: false,
    },
    detail: {
      serviceNameSearch: ['detail'],
      introText:
        '<b>Schedule a quality car detail today!</b> Yoshi delivers car details and washes at your home or office so you can save time and money.',
      imgIntroSrc: '/assets/images/webflow/webflow-start-intro-detail.png',
      imgMainSrc: '/assets/images/webflow/webflow-start-main-wash.png',
      footerCTA:
        'Have a fleet of 5+ vehicles? <a href="https://www.startyoshi.com/fleet" target="_top">Get a quote</a>',
      virtual: false,
    },
  }
  return webflowVariants[key];
};
