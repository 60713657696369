import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { FlowPage } from '../../flow-director';
import { KeyboardService } from 'src/app/services/keyboard/keyboard.service';
import { LoadingAlertService } from 'src/app/services/loading-alert/loading-alert.service';
import { User } from '@sentry/angular';
import { UserParams } from 'src/app/models/user';
import { UserService } from 'src/app/services/api/user/user.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'ysh-webflow-login',
  templateUrl: './webflow-login.page.html',
  styleUrls: ['./webflow-login.page.scss'],
})
export class WebflowLoginPage implements OnInit, FlowPage {
  @ViewChild('inputPhone') inputPhone: ElementRef;
  @Input() phone;
  @Input() onDismiss: () => void;
  @Input() onComplete: (data: string) => void;

  formData: UserParams = {};

  phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  constructor(
    private analytics: AnalyticsService,
    private keyboardService: KeyboardService,
    private userService: UserService,
    private loadingAlert: LoadingAlertService
  ) {}

  // life cycle hooks
  ngOnInit() {
    this.analytics.trackView('LoginFlowPage');
  }

  ionViewWillEnter() {
    this.keyboardService.disableScroll(true);
    setTimeout(() => {
      this.inputPhone.nativeElement.focus();
    }, 600);
  }

  ionViewWillLeave() {
    this.keyboardService.close();
    this.keyboardService.disableScroll(false);
  }

  // Data
  phoneValidates() {
    return this.formData.phone && this.formData.phone.length === 14;
  }

  // Actions

  didTapEnter() {
    if (!this.phoneValidates()) {
      return;
    }
    this.loadingAlert.showLoader();
    this.userService
      .sendPin(this.formData.phone!)
      .pipe(
        finalize(() => {
          this.loadingAlert.dismissLoader();
        })
      )
      .subscribe(
        (data) => {
          this.onComplete?.(this.formData.phone!);
        },
        (error) => {
          this.loadingAlert.showToastAlert(error);
        }
      );
  }

  didTapBack() {
    this.onDismiss?.();
  }
}
