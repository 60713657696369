import { Order, OrderStatus } from 'src/app/models/order';
import { finalize, take } from 'rxjs/operators';

import { AddressService } from '../../api/address/address.service';
import { Day } from 'src/app/models/day';
import { Injectable } from '@angular/core';
import { LoadingAlertService } from '../../loading-alert/loading-alert.service';
import { ModalService } from '../../modal/modal.service';
import { OrderFlowControllerPage } from 'src/app/pages/flows/ordering/order-flow-controller/order-flow-controller.page';
import { OrderScheduleService } from '../order-schedule/order-schedule.service';
import { OrderService } from '../../api/order/order.service';
import { Service } from 'src/app/models/service';
import { ServiceGroup } from 'src/app/models/service-group';
import { ServiceName } from 'src/app/models/service-type';
import { ServiceOrder } from 'src/app/models/service-order';
import { ServiceSheetPage } from 'src/app/pages/services/service-sheet/service-sheet.page';
import { ServiceTypeSheet } from 'src/app/components/service-type-sheet/service-type-sheet';
import { ShiftName } from 'src/app/models/shift';
import { UserAddress } from 'src/app/models/user-address';
import { Vehicle } from 'src/app/models/vehicle';
import { VehicleSubscription } from 'src/app/models/vehicle-subscription';
import { VehiclesService } from '../../api/vehicles/vehicles.service';
import moment from 'moment';

/***
 * Opens the correct flows for the creation of orders and services orders
 */

@Injectable({
  providedIn: 'root',
})
export class OrderCreateService {
  constructor(
    private orderSchedule: OrderScheduleService,
    private loadingAlertCtrl: LoadingAlertService,
    private orderService: OrderService,
    private modalService: ModalService,
    private addressService: AddressService,
    private vehicleService: VehiclesService
  ) {}

  // Create flows

  async showServiceTypeOptions() {
    const availableServices = await this.orderSchedule.availableServiceGroups$
      .pipe(take(1))
      .toPromise();
    const providerServices = availableServices.filter((service) => service.basicService.provider);
    if (!providerServices?.length) {
      return;
    } else if (providerServices.length === 1) {
      const serviceGroup = providerServices[0];
      return this.showOrderFlowForService(null, serviceGroup);
    } else {
      this.showServiceTypeSheet(providerServices);
    }
  }

  private showServiceTypeSheet(services: ServiceGroup[]) {
    this.modalService.openPage(
      {
        component: ServiceTypeSheet,
        componentProps: {
          onSelect: (service) => this.showOrderFlowForService(null, service),
          services: services,
        },
      },
      true
    );
  }

  async showOrderFlowForService(service: Service | null, serviceGroup: ServiceGroup) {
    const vehicle = this.vehicleService.selectedVehicle$.value;
    const selectedService = service || serviceGroup.basicService;
    if (vehicle && !selectedService.provider) {
      const compatibleVisit = await this.orderSchedule.findCompatibleVisit(
        vehicle,
        selectedService
      );
      if (!compatibleVisit) {
        this.promptforCompatibleOrder(selectedService);
      } else {
        this.showServiceSheet(selectedService, serviceGroup);
      }
    } else {
      const forGas = serviceGroup.serviceType.name === ServiceName.Gas;
      const selectedService =
        forGas && serviceGroup.services.length === 1 ? serviceGroup.basicService : null;
      this.showOrderFlow(serviceGroup, service || selectedService);
    }
  }

  private async promptforCompatibleOrder(service: Service) {
    const availableServices = await this.orderSchedule.availableServiceGroups$
      .pipe(take(1))
      .toPromise();
    const compatibleServices = availableServices.filter((s) =>
      service.shouldScheduleWith?.includes(s.serviceType.name)
    );
    if (compatibleServices.length) {
      const prompt = `This is an "add-on". You'll first need to schedule a compatible service.`;
      this.loadingAlertCtrl.showConfirmationDialog(prompt, 'See Options', () => {
        this.showServiceTypeSheet(compatibleServices);
      });
    } else {
      this.loadingAlertCtrl.presentAlert(
        'Error Scheduling',
        'This service is currently unavailable'
      );
    }
  }

  private showOrderFlow(serviceGroup: ServiceGroup, service?: Service | null) {
    const userAddress = this.addressService.selectedAddress$.value;
    const vehicle = this.vehicleService.selectedVehicle$.value;
    if (!userAddress || !vehicle) {
      return;
    }
    this.modalService.openPage({
      component: OrderFlowControllerPage,
      animated: false,
      componentProps: {
        onComplete: () => this.modalService.dismissModal(),
        onDismiss: () => this.modalService.dismissModal(),
      },
      props: {
        userAddress,
        vehicle,
        service: service,
        serviceGroup,
      },
    });
  }

  private showServiceSheet(service: Service, serviceGroup: ServiceGroup): Promise<any> {
    const vehicle = this.vehicleService.selectedVehicle$.value;
    if (!vehicle) {
      return Promise.resolve();
    }
    return this.modalService.openPage(
      {
        component: ServiceSheetPage,
        componentProps: {
          serviceGroup: serviceGroup,
          selectedService: service,
          onServiceAdded: (service, frequency) => {
            this.saveServiceOrder(vehicle, service, frequency);
          },
        },
      },
      true
    );
  }

  // Edit Flows

  async saveServiceOrder(vehicle: Vehicle, service: Service, selectedFrequency, selectedDate?) {
    const visit = await this.orderSchedule.findCompatibleVisit(vehicle, service);
    if (!visit) {
      return;
    }
    const date = selectedDate
      ? moment(selectedDate).format('YYYY-MM-DD')
      : moment(visit.order.date).format('YYYY-MM-DD');

    this.loadingAlertCtrl.showLoader();
    const request = this.orderService
      .createServiceOrder(vehicle, {
        serviceUid: service.uid,
        frequency: selectedFrequency ? selectedFrequency : service.defaultFrequency,
        userAddressUid: visit.order.userAddress.uid,
        date,
      })
      .pipe(finalize(() => this.loadingAlertCtrl.dismissLoader()));
    request.subscribe(
      (order) => {
        this.didFinishSavingOrder(order, date);
      },
      () => {
        this.loadingAlertCtrl.showToastAlert('Error placing order.');
      }
    );
    return request;
  }

  private didFinishSavingOrder(order: ServiceOrder, date) {
    const prompt =
      order.status === OrderStatus.Proposed
        ? `We've received your request and will confirm shortly!`
        : `Your ${order.service.title} has been scheduled`;

    this.loadingAlertCtrl.showToastConfirmation(prompt);
  }
}

export interface TimeWindow {
  title: ShiftName;
  startTime: number;
  endTime: number;
}

export interface OrderCreateParams {
  order: Order;
  subscription?: Nullable<VehicleSubscription>;
  days?: Day[];
  shift?: ShiftName;
  startTime?: number;
  endTime?: number;
  slotStartTime?: string;
  address?: UserAddress;
  mapInfo?: any;
  driverNote?: Nullable<string>;
  persistent?: boolean;
}
