import * as humps from 'humps';

import { ConnectableObservable, Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, map, publish } from 'rxjs/operators';

import { EnvironmentService } from '../environment/environment.service';
import { Injectable } from '@angular/core';
import { SessionService } from './../session/session.service';

export interface ApiOptions {
  url: string;
  method?: string;
  params?: any;
  body?: any;
  includePageInfo?: boolean;
  convertToParams?: boolean;
  auth_key?: string;
  withCredentials?: boolean;
  headers?: HttpHeaders;
  observe?: 'body' | 'events' | 'response';
  compareEtag?: boolean;
}

export interface PageInfo {
  total_entries: number;
  total_pages: number;
  current_page: number;
  previous_page: number;
  next_page: number;
}

export interface PaginatedResponse<T> {
  pageInfo: PageInfo;
  response: T;
}

/**
 * Wrapper class for http requests made to the Yoshi server. This class is not intended to be called directly by any component,
 * instead one of the available service providers should be used (e.g UserProvider).
 */

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  static readonly USER_UID = 'CURRENT_USER_UID';
  static readonly UNAUTHORIZED_ERROR = new Error('Not logged in');
  authData?: Nullable<AuthData>;
  etagsMap = {};

  constructor(
    private http: HttpClient,
    private session: SessionService,
    private environment: EnvironmentService
  ) {}

  call(options: ApiOptions): Observable<any | PaginatedResponse<any>> {
    if (!this.isAuthorized(options)) {
      return throwError(ApiService.UNAUTHORIZED_ERROR);
    }
    this.sanitizeOptions(options);
    this.setHeaders(options);
    options.observe = options.includePageInfo ? 'response' : 'body';
    const response = this.http.request(options.method || 'GET', options.url, options).pipe(
      catchError(this.parseError),
      map((res: Response) => {
        if (options.observe === 'response') {
          const apiCallResponse = {
            response: res.body,
            status: res.status,
          };
          if (options.includePageInfo) {
            apiCallResponse['pageInfo'] = JSON.parse(res.headers.get('x-pagination') || '');
          }
          if (options.compareEtag) {
            apiCallResponse['etag'] = res.headers.get('etag');
            const url = options.url + (options.params ? 'page=' + options.params.page || '' : '');
            this.etagsMap[url] = res.headers.get('etag');
          }
          return apiCallResponse;
        } else if (options.observe === 'body') {
          return res;
        }
      }),
      publish()
    ) as ConnectableObservable<any>;
    response.connect();
    return response;
  }

  private isAuthorized(options: ApiOptions) {
    const loggedIn = this.authData && this.authData.user_uid;
    return !options.url.includes(ApiService.USER_UID) || loggedIn;
  }
  private sanitizeOptions(options: ApiOptions) {
    options.method = options.method || 'GET';
    options.body = options.body ? humps.decamelizeKeys(options.body, { separator: '_' }) : {};
    options.body = options.convertToParams ? this.convertToParams(options.body) : options.body;
    options.params = options.params ? humps.decamelizeKeys(options.params, { separator: '_' }) : {};
    if (this.authData) {
      options.url = options.url.replace(ApiService.USER_UID, this.authData.user_uid);
      options.params.auth_key = this.authData.auth_key;
    }
    const baseURL = this.environment.serverVars().apiEndpoint;
    options.url = baseURL + options.url;
    return options;
  }

  private setHeaders(options: ApiOptions) {
    let headers = new HttpHeaders();
    if (!(options.body instanceof FormData)) {
      headers.append('Content-Type', 'application/x-www-form-urlencoded');
    }
    const url = options.url + (options.params ? 'page=' + options.params.page || '' : '');
    if (options.compareEtag && this.etagsMap[url]) {
      headers = headers.append('If-None-Match', this.etagsMap[url]);
    }
    options.headers = headers;
    return options;
  }

  // Necessary when request body includes an array of strings
  // TODO: Find a cleaner way to do this on client or server
  private convertToParams(body: any = {}) {
    let params = new HttpParams();
    for (let key in body) {
      if (body[key] instanceof Array) {
        const list = body[key];
        for (let item of list) {
          params = params.append(`${key}[]`, item);
        }
      } else {
        params = params.set(key, body[key]);
      }
    }
    return params;
  }

  parseError(error) {
    if (error && error.status == '304') {
      // 304 is technically not an error, however, angular treats it as one.
      return throwError({ status: 304, statusText: 'not-modified' });
    }
    let errorMessage = '';
    console.log('handle error', error);
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      const errorResponse = error && error.error;
      const errorArray = errorResponse && errorResponse.errors;
      const firstError = errorArray && errorArray.length && errorArray[0];
      if (firstError && firstError.param && firstError.message) {
        errorMessage = `Invalid ${firstError.param.replace('_', ' ')} - ${firstError.message}`;
      } else if (firstError && firstError.message) {
        errorMessage = firstError.message;
      } else if (errorResponse && errorResponse.message) {
        errorMessage = errorResponse.message;
      } else if (error) {
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      } else {
        errorMessage = 'Unknown Error';
      }
    }
    return throwError(errorMessage);
  }
}

@Injectable()
export class EmptySubscriber {
  next = () => {};
  error = () => {};
  complete = () => {};
}

export interface AuthData {
  auth_key: string;
  user_uid: string;
  user_id?: string;
}
