import * as a from 'indefinite';

import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IonContent, IonSlides } from '@ionic/angular';
import { map, takeUntil } from 'rxjs/operators';

import { AddressService } from 'src/app/services/api/address/address.service';
import { Benefit } from 'src/app/models/benefit';
import { DollarsAndCentsPipe } from 'src/app/pipes/dollars-and-cents/dollars-and-cents';
import { FlowPage } from '../../flows/flow-director';
import { FuelType } from 'src/app/models/fuel-type';
import { LoyaltyService } from 'src/app/services/api/loyalty/loyalty.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { NavigationService } from 'src/app/navigation.service';
import { Order } from 'src/app/models/order';
import { OrderScheduleService } from 'src/app/services/scheduling/order-schedule/order-schedule.service';
import { OrderService } from 'src/app/services/api/order/order.service';
import { PricePerGallonDetailsModalPage } from '../../home/price-per-gallon-details-modal/price-per-gallon-details-modal.page';
import { Service } from 'src/app/models/service';
import { ServiceGroup } from 'src/app/models/service-group';
import { ServiceOrder } from 'src/app/models/service-order';
import { ServiceSubscription } from 'src/app/models/service-subscription';
import { UserService } from 'src/app/services/api/user/user.service';
import { Vehicle } from 'src/app/models/vehicle';
import { VehicleSubscription } from 'src/app/models/vehicle-subscription';
import { VehiclesService } from 'src/app/services/api/vehicles/vehicles.service';
import { Visit } from 'src/app/models/visit';

@Component({
  selector: 'ysh-service-detail',
  templateUrl: './service-detail.page.html',
  styleUrls: ['./service-detail.page.scss'],
})
export class ServiceDetailPage implements OnInit, OnDestroy, FlowPage {
  @ViewChild('content') content: IonContent;
  @ViewChild('slides') slides: IonSlides;

  vehicle: Nullable<Vehicle>;
  fuelTypes: FuelType[];

  serviceSubscription$: Observable<Nullable<ServiceSubscription>>;
  serviceOrder$: Observable<Nullable<ServiceOrder>>;
  vehicleSubscription$: Observable<Nullable<VehicleSubscription>>;
  order$: Observable<Nullable<Order>>;
  service$: BehaviorSubject<Nullable<Service>> = new BehaviorSubject(null);
  selectedService$: BehaviorSubject<Nullable<Service>> = new BehaviorSubject(null);

  nextVisit$: Observable<Nullable<Visit>>;
  hasSubscription$: Observable<boolean>;
  benefit$: Observable<Benefit>;

  @Input() onDismiss: () => void;
  @Input() onComplete: (service: Service) => void;
  @Input() preventBackNavigation?: boolean = false;
  @Input() isSourcedFromUpSellFlow = false;
  @Input() serviceGroup: ServiceGroup;
  @Input() onServ: ServiceGroup;
  @Input() showConfirm = false;
  @Input() hideFuelDiscount = false;
  @Input() showCredit = false;

  stars$: Observable<number | undefined>;

  private unsubscribe: Subject<void> = new Subject();

  constructor(
    public orderSchedule: OrderScheduleService,
    public loyaltyService: LoyaltyService,
    public addressService: AddressService,
    public vehicleService: VehiclesService,
    private orderService: OrderService,
    private navigationService: NavigationService,
    private modalService: ModalService,
    private userService: UserService,
    protected dollarsAndCents: DollarsAndCentsPipe
  ) {
    this.loadData();
  }

  // life cycle hooks
  ngOnInit(): void {
    if (this.navigationService.currentRouteState) {
      this.serviceGroup = this.navigationService.currentRouteState.serviceGroup;
    }
    this.vehicle = this.vehicleService.selectedVehicle$.value;
    this.stars$ = this.loyaltyService.pointMultiplerForService$(this.serviceGroup.serviceType.name);
    this.selectedService$.next(this.serviceGroup.basicService);
    this.subscribeToData();
    this.loadFuelTypes();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  // Data

  subscribeToData() {
    const selectWithVehicleAndSub = (item: { vehicle: Vehicle; service: Service }) => {
      return (
        item.vehicle.uid === this.vehicle?.uid &&
        item.service.serviceType.name === this.serviceGroup.serviceType.name
      );
    };
    this.serviceSubscription$ = this.orderService.serviceSubscriptions$.pipe(
      map((subscriptions) => subscriptions.find(selectWithVehicleAndSub))
    );

    this.serviceOrder$ = this.orderService.serviceOrders$.pipe(
      map((orders) => orders.find(selectWithVehicleAndSub))
    );

    this.vehicleSubscription$ = this.orderService.vehicleSubscriptions$.pipe(
      map((orders) => orders.find(selectWithVehicleAndSub))
    );

    this.order$ = this.orderService.orders$.pipe(
      map((orders) => orders.find(selectWithVehicleAndSub))
    );

    this.nextVisit$ = this.orderService.visits$.pipe(
      map((visits) =>
        visits.find(
          (visit) =>
            this.vehicle && visit.hasServiceForVehicle(this.serviceGroup.serviceType, this.vehicle)
        )
      )
    );

    combineLatest([this.order$, this.serviceOrder$, this.selectedService$])
      .pipe(
        map((data) => this.deriveService(data)),
        takeUntil(this.unsubscribe)
      )
      .subscribe(this.service$);

    this.hasSubscription$ = combineLatest([
      this.serviceSubscription$,
      this.vehicleSubscription$,
    ]).pipe(map(([sSub, vSub]) => Boolean(sSub || vSub)));
  }

  private deriveService([order, serviceOrder, selected]: [
    Nullable<Order>,
    Nullable<ServiceOrder>,
    Nullable<Service>
  ]) {
    return selected || order?.service || serviceOrder?.service || this.serviceGroup.basicService;
  }

  loadData() {
    this.orderService.getServiceSubscriptions();
    this.orderService.getServiceOrders();
    this.userService.getAppliedBenefits();
  }

  loadFuelTypes() {
    this.vehicleService.getFuelsTypes().subscribe(
      (data) => {
        this.fuelTypes = data;
      },
      (error) => {
        console.log('error loading fuel types', error);
      }
    );
  }

  // Actions

  didTapBack() {
    if (this.onDismiss) {
      this.onDismiss();
    } else {
      this.navigationService.goBack();
    }
  }

  didSelectService(service: Service) {
    this.selectedService$.next(service);
  }

  didTapPriceDetail() {
    if (!this.vehicle || !this.addressService.selectedAddress$.value) {
      return;
    }
    const componentProps = {
      modal: true,
      address: this.addressService.selectedAddress$.value,
      vehicle: this.vehicle,
    };
    this.modalService.open({
      component: PricePerGallonDetailsModalPage,
      componentProps,
    });
  }

  didTapConfirm() {
    if (this.selectedService$.value) {
      this.onComplete?.(this.selectedService$.value);
    }
  }

  // View Helpers

  get showShadow() {
    return ['white', '#ffffff', '#fff'].includes(
      this.serviceGroup.serviceType.presentation.backgroundColor.toLowerCase()
    );
  }
}
