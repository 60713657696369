export interface UberCityProps {
  serviceDaysWindow: string,
  serviceTimeWindow: string,
  servicePrice: string,
}

export const uberCities: { [key: string]: UberCityProps } = {
  san_francisco: {
    serviceDaysWindow: 'daily (except Sundays)',
    serviceTimeWindow: '12pm-8pm',
    servicePrice: '$40',
  },
  los_angeles: {
    serviceDaysWindow: 'Monday through Friday',
    serviceTimeWindow: '9am-5pm',
    servicePrice: '$40',
  },
}
