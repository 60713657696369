import { Component, Input, OnInit } from '@angular/core';
import { ModalService, YshModals } from 'src/app/services/modal/modal.service';

import { Service } from 'src/app/models/service';

@Component({
  selector: 'ysh-request-received-modal',
  templateUrl: './request-received-modal.page.html',
  styleUrls: ['./request-received-modal.page.scss'],
})
export class RequestReceivedModalPage implements OnInit {
  static PAGE_NAME = YshModals.RequestReceivedModalPage;

  readonly description: string;
  readonly buttonText: string = `Sounds Good!`;
  readonly headerText: string = `Request received!`;
  @Input() service: Service;
  @Input() sameDay = false;

  constructor(private modalService: ModalService) {
    const orderType = this.service ? this.service.serviceType.title.toLowerCase() : 'order';
    this.description = this.service?.slotScheduling
      ? `Thank you for your ${orderType} request! We will see you soon!`
      : `Thank you for your ${orderType} request! We are searching for a place on our schedule and will let you know when your order is confirmed.`;
  }

  ngOnInit() {
    console.log('ionViewDidLoad RequestReceivedModalPage');
  }

  didTapSoundsGood() {
    this.modalService.dismissModal();
  }
}
