import { Component, Input, OnInit } from '@angular/core';

import { AddressService } from 'src/app/services/api/address/address.service';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { Day } from 'src/app/models/day';
import { FlowPage } from '../../../flow-director';
import { ModalService } from 'src/app/services/modal/modal.service';
import { OrderService } from 'src/app/services/api/order/order.service';
import { Service } from 'src/app/models/service';
import { TimeSlot } from 'src/app/models/time-slot';
import { UserAddress } from 'src/app/models/user-address';

export interface DayTimeSelectorPageProps {
  service: Service;
  userAddress: UserAddress;
}

export interface DayTimeSelectorPageForm {
  slot?: TimeSlot;
}

@Component({
  selector: 'ysh-day-time-selector',
  templateUrl: './day-time-selector.page.html',
  styleUrls: ['./day-time-selector.page.scss'],
})
export class DayTimeSelectorPage implements OnInit, FlowPage {
  @Input() onComplete: (slot: TimeSlot) => void;
  @Input() onDismiss: () => void;
  @Input() preventBackNavigation?: boolean = false;
  @Input() props: DayTimeSelectorPageProps;

  loading = true;
  form: DayTimeSelectorPageForm = {};
  days: Day[] = [];
  selectedDay?: Day;

  private dayAvailability = {};

  constructor(
    private addressService: AddressService,
    private orderService: OrderService,
    private analytics: AnalyticsService,
    private modalService: ModalService
  ) {}

  ngOnInit() {
    this.analytics.trackView('DayTimeSelectorPage');
    const days = this.props.userAddress.getDays(24, this.props.service.serviceType.name) || [];
    this.days = this.sortDays(days);
    this.orderService.getUnservicedDays().subscribe((days) => {
      this.dayAvailability = {};
    });
    this.fetchTimeSlots();
  }

  private sortDays(days: Day[]) {
    return days.sort((a, b) => a.nextDate.getTime() - b.nextDate.getTime());
  }

  // Actions

  didTapOnDay(day) {
    this.selectedDay = day;
  }

  didTapOnSlot(slot: TimeSlot) {
    this.form.slot = slot;
  }

  didTapDismiss() {
    this.modalService.dismissModal();
  }

  didTapBack() {
    this.onDismiss?.();
  }

  didTapDone() {
    this.onComplete(this.form.slot!);
  }

  // Data

  private fetchTimeSlots() {
    return this.addressService
      .getTimeSlots(
        this.props.userAddress,
        this.days[0].nextDate,
        this.days[this.days.length - 1].nextDate,
        this.props.service
      )
      .subscribe((slots) => {
        this.addSlotsToDays(slots, this.days);
        this.setDefaultDay();
        this.loading = false;
      });
  }

  private addSlotsToDays(slots: TimeSlot[], days: Day[]) {
    days.forEach((day: Day) => {
      const daySlots = slots.filter((slot) => slot.date.toDateString() == day.date.toDateString());
      day.slots = day.slots.concat(daySlots);
    });
  }

  private setDefaultDay() {
    this.selectedDay = this.days.find((day) => day.slots.find((slot) => slot.available));
    this.form.slot = this.selectedDay?.slots.find((slot) => slot.available);
  }

  // Helpers

  isDaySelected(someDay: Day) {
    return this.selectedDay?.dayIndex === someDay.dayIndex;
  }

  isDayServiced(day: Day) {
    const key = day.nextDate.toDateString();
    if (!(key in this.dayAvailability)) {
      const serviced = this.orderService.isDateServiced(day.nextDate);
      this.dayAvailability[key] = serviced;
    }

    const nonSlotSchedulingRoutes = day.routes.filter((r) => r.slotScheduling === false);

    return (
      this.dayAvailability[key] &&
      (this.props.service.slotScheduling
        ? day.slots.length > 0
        : nonSlotSchedulingRoutes.length > 0)
    );
  }
}
