import { Component, Input, OnInit } from '@angular/core';
import { ServiceGroup } from 'src/app/models/service-group';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { FlowPage } from '../../flow-director';

export interface WebflowEndPageProps {
  serviceGroup: ServiceGroup;
}
@Component({
  selector: 'ysh-webflow-end-page',
  templateUrl: './webflow-end.page.html',
  styleUrls: ['./webflow-end.page.scss'],
})
export class WebflowEndPage implements OnInit, FlowPage {
  @Input() props: WebflowEndPageProps;
  onComplete: (result: any) => void;
  onDismiss: () => void;

  constructor(private analytics: AnalyticsService) {}

  // life cycle hooks

  ngOnInit() {
    this.analytics.trackView('WebflowEndPage');
  }
}
