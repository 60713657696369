import * as moment from 'moment';

import { BaseEntity, MemoizeGetters, Transform, toDate } from './base';

@MemoizeGetters
export class TimeSlot extends BaseEntity {
  available: boolean;

  @Transform(toHMAFormat)
  startTime: string;

  @Transform(toDate)
  date: Date;

  get twelveHourStartTime() {
    return moment(`${this.date.toDateString()} ${this.startTime}`).format('h:mma');
  }
}

function toHMAFormat(value): string {
  return moment.parseZone(value).format('HH:mm');
}
