import { ApiService, EmptySubscriber } from '../api.service';
import { AppState, getAll, getCollection } from 'src/state/state';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { Benefit, BenefitTarget } from 'src/app/models/benefit';
import {
  MemberSubscription,
  MemberSubscriptionCreateParams,
} from 'src/app/models/member-subscription';
import { MemberTier, MembershipTerm } from 'src/app/models/member-tier';
import { Selector, Store, createSelector } from '@ngrx/store';
import { filter, finalize, map, tap } from 'rxjs/operators';

import { CollectionActions } from 'src/state/actions';
import { Injectable } from '@angular/core';
import { MemberPolicy } from '../../../models/membership-policy';
import { PercentPipe } from '@angular/common';
import { Storage } from '@ionic/storage';
import { UserService } from '../user/user.service';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class MembershipService {
  static readonly MEMBERSHIP_CANCELLATION_DISCOUNT_VALUE = 10;
  static readonly LAST_PROMPTED_DATE = 'promptedForUpgradeDate';
  tiers$: Observable<MemberTier[]>;
  monthlyTier$: Observable<Nullable<MemberTier>>;
  annualTier$: Observable<Nullable<MemberTier>>;
  membership$: Observable<Nullable<MemberSubscription>>;
  memberBenefits$: Observable<Benefit[]>;
  memberPolicy$: BehaviorSubject<Nullable<MemberPolicy>>;
  nonMemberBenefits$: Observable<Benefit[]>;
  freeTrialMemberBenefit$: Observable<Nullable<Benefit>>;
  sponsoredFreeMemberBenefit$: Observable<Nullable<Benefit>>;
  discountedFeeAfterTrial$: Observable<number>;
  freeTrialInEffect$: Observable<boolean>;
  eligibleForAnnualUpgrade$: Observable<boolean>;
  discountedMonthlyCurrentFee$: Observable<number>;
  discountedAnnualCurrentFee$: Observable<number>;
  discountedMonthlyTier$: Observable<number>;
  totalMonthlyDiscount$: Observable<number>;
  membershipSponsors$: Observable<string[]>;

  annualSavings: string | null;
  monthlyPremium: string | null;

  constructor(
    private api: ApiService,
    private userService: UserService,
    private store: Store<AppState>,
    private collectionActions: CollectionActions,
    private storage: Storage,
    private percentPipe: PercentPipe
  ) {
    this.tiers$ = store.select(this.selectTiers());
    this.annualTier$ = store.select(this.selectAnnualTier());
    this.monthlyTier$ = store.select(this.selectMonthlyTier());
    this.membership$ = store.select(this.selectMembership());
    this.memberBenefits$ = store.select(this.selectNonTrialMemberBenefits());
    this.nonMemberBenefits$ = store.select(this.selectNonMemberBenefits());
    this.freeTrialMemberBenefit$ = store.select(this.selectFreeTrialBenefit());
    this.freeTrialInEffect$ = store.select(this.selectFreeTrialInEffect());
    this.sponsoredFreeMemberBenefit$ = store.select(this.selectSponsoredFreeMembershipBenefit());
    this.discountedFeeAfterTrial$ = store.select(this.selectDiscountedFeeAfterTrial());
    this.eligibleForAnnualUpgrade$ = store.select(this.selectEligibleForAnnual());
    this.discountedMonthlyCurrentFee$ = store.select(this.selectDiscountedMonthlyFee());
    this.discountedAnnualCurrentFee$ = store.select(this.selectDiscountedAnnualFee());
    this.discountedMonthlyTier$ = store.select(this.selectedDiscountedMonthlyTier());
    this.totalMonthlyDiscount$ = store.select(this.selectTotolMonthlyDiscounts());
    this.membershipSponsors$ = store.select(this.selectMembershipSponsors());
    this.memberPolicy$ = new BehaviorSubject(null);

    this.userService.isLoggedIn$.subscribe((isLoggedIn) => {
      if (isLoggedIn) {
        this.getMembershipTiers();
        this.getMemberPolicy();
      }
    });
  }

  /**
   * DEPRECATED! We should use new selector-based observables to access data
   * Provides a way to subscribe to the latest membership info, including
   * current subscription, applicable benefits, and a calculation of pricing
   * after all valid discounts have been applied
   * @returns {Observable<MembershipInfo>} observable with all latest info
   */

  getCurrentMembershipInfo(): Observable<MembershipInfo> {
    const currentUser$ = this.userService.currentUser$.pipe(filter((user) => user != null));
    const benefits = this.userService.benefits$;
    const tiersLoaded$ = this.store
      .select((state) => getCollection(state, MemberTier).loaded)
      .pipe(filter((loaded) => loaded));

    return combineLatest([currentUser$, benefits, this.annualTier$, tiersLoaded$]).pipe(
      map((response) => {
        const user = response[0];
        const subscription = user ? user.fuelMembership : null;
        const benefits = subscription
          ? this.filterMemberSubscriptionBenefits(response[1], subscription)
          : [];
        let annualTier = response[2];
        return new MembershipInfo(subscription, benefits, annualTier);
      })
    );
  }

  private filterMemberSubscriptionBenefits(benefits: Benefit[], membership: MemberSubscription) {
    const now = new Date();
    const expiration = membership.freeMembershipExpiration;
    // Add day to expiration to ensure we are checking the day after free membership expires
    expiration.setDate(expiration.getDate() + 1);
    const benefitsFilter = (benefit: Benefit) =>
      benefit.target === 'MemberSubscription' && benefit.isValidOn(now);
    return benefits.filter(benefitsFilter);
  }

  // Upgrade Prompts
  setLastPromptedDate() {
    this.storage.set(MembershipService.LAST_PROMPTED_DATE, new Date());
  }

  userHasBeenPromptedRecently() {
    return this.storage.get(MembershipService.LAST_PROMPTED_DATE).then((lastPrompted) => {
      if (lastPrompted) {
        const nowMoment = moment(new Date());
        // Last prompt date was within 30 days
        return nowMoment.diff(lastPrompted, 'days') < 30;
      }
      return false;
    });
  }

  // Data

  getMembershipTiers() {
    const request = this.api
      .call({
        method: 'GET',
        url: '/member_subscriptions/tiers',
      })
      .pipe(
        map((result) => {
          const sorted = result.map((item) => new MemberTier(item));
          this.sortTiersById(sorted);
          return sorted;
        }),
        tap((tiers) => {
          this.store.dispatch(this.collectionActions.set(MemberTier, tiers));
          this.calculateSavings(tiers);
        })
      );
    request.subscribe(new EmptySubscriber());
    return request;
  }

  private sortTiersById(tiers: MemberTier[]) {
    tiers.sort((a, b) => a.monthlyFee - b.monthlyFee);
  }

  private calculateSavings(tiers: MemberTier[]) {
    const monthlyPlan = tiers.find((tier) => tier.term === MembershipTerm.Monthly);
    const annualPlan = tiers.find((tier) => tier.term === MembershipTerm.Annual);
    if (monthlyPlan && annualPlan) {
      this.annualSavings = this.percentPipe.transform(
        1 - annualPlan.monthlyFee / monthlyPlan.monthlyFee,
        '1.0-0'
      );
      this.monthlyPremium = this.percentPipe.transform(
        monthlyPlan.monthlyFee / annualPlan.monthlyFee - 1,
        '1.0-0'
      );
    }
  }

  getMemberPolicy() {
    const request = this.api
      .call({
        method: 'GET',
        url: `/users/${ApiService.USER_UID}/membership_policy`,
      })
      .pipe(
        map((result) => new MemberPolicy(result)),
        tap((result) => {
          this.memberPolicy$.next(result);
        })
      );
    request.subscribe(new EmptySubscriber());
    return request;
  }

  createMemberSubscription(params: MemberSubscriptionCreateParams) {
    const request = this.api
      .call({
        method: 'POST',
        url: `/users/${ApiService.USER_UID}/member_subscriptions`,
        body: params,
      })
      .pipe(
        map((item) => new MemberSubscription(item)),
        finalize(() => {
          this.userService.getUser();
        })
      );
    return request;
  }

  cancellationReasons() {
    const reasons: { [key: string]: string }[] = [];
    // tslint:disable-next-line:forin
    for (const item in CancellationReasons) {
      reasons.push({
        key: item,
        value: CancellationReasons[item],
      });
    }
    return reasons;
  }

  cancelMemberSubscription(membershipUid: string, reason: string, notes?: Nullable<string>) {
    const request = this.api.call({
      method: 'POST',
      url: `/member_subscriptions/${membershipUid}/cancel`,
      body: {
        cancellation_reason:
          CancellationReasonsMap[reason] || CancellationReasonsMap[CancellationReasons.Other],
        cancellation_note: notes || null,
      },
    });
    return request;
  }

  applyMembershipDiscount(promoCode: string) {
    return this.userService.sendPromoCode(promoCode);
  }

  formattedPaidThroughString(membershipInfo: MembershipInfo): string {
    if (
      !membershipInfo ||
      !membershipInfo.subscription ||
      !membershipInfo.subscription.datePaidThrough
    ) {
      return 'N/A';
    }
    return moment(membershipInfo.subscription.datePaidThrough).format('M/D/YYYY');
  }

  // Selectors

  private selectTiers(): Selector<AppState, MemberTier[]> {
    return (state: AppState) => getAll(state, MemberTier);
  }

  private selectBenefits(): Selector<AppState, Benefit[]> {
    return (state: AppState) => getAll(state, Benefit);
  }

  private selectMembership(): Selector<AppState, Nullable<MemberSubscription>> {
    return createSelector(
      (state: AppState) => getAll(state, MemberSubscription),
      (memberships) => memberships.find((membership) => membership.active)
    );
  }

  private selectMonthlyTier(): Selector<AppState, Nullable<MemberTier>> {
    return createSelector(this.selectTiers(), (tiers) =>
      tiers.find((tier) => tier.term === MembershipTerm.Monthly)
    );
  }

  private selectAnnualTier(): Selector<AppState, Nullable<MemberTier>> {
    return createSelector(this.selectTiers(), (tiers) =>
      tiers.find((tier) => tier.term === MembershipTerm.Annual)
    );
  }

  private selectMemberBenefits(): Selector<AppState, Benefit[]> {
    return createSelector(this.selectBenefits(), (benefits) =>
      benefits.filter((benefit) => {
        const valid = benefit.isValidOn(new Date());
        const membershipTarget = benefit.target === BenefitTarget.MemberSubscription;
        return valid && membershipTarget;
      })
    );
  }

  private selectFreeTrialBenefit(): Selector<AppState, Nullable<Benefit>> {
    return createSelector(
      this.selectMemberBenefits(),
      this.selectMembership(),
      (benefits, membership) =>
        benefits.find((benefit) => {
          const isFree = membership && Number(membership.fee) <= Number(benefit.value);
          const isTrial = benefit.isMembershipTrial;
          return isFree && isTrial;
        })
    );
  }

  private selectNonTrialMemberBenefits(): Selector<AppState, Benefit[]> {
    return createSelector(this.selectMemberBenefits(), (benefits) =>
      benefits.filter((benefit) => !benefit.isMembershipTrial)
    );
  }

  private selectNonMemberBenefits(): Selector<AppState, Benefit[]> {
    return createSelector(this.selectBenefits(), (benefits) =>
      benefits.filter((benefit) => benefit.target !== BenefitTarget.MemberSubscription)
    );
  }

  private selectSponsoredFreeMembershipBenefit(): Selector<AppState, Nullable<Benefit>> {
    return createSelector(
      this.selectMemberBenefits(),
      this.selectMembership(),
      this.selectMonthlyTier(),
      (benefits, membership, monthlyTier) => {
        const monthlyFee = membership
          ? Number(membership.fee)
          : monthlyTier
          ? Number(monthlyTier.fee)
          : 0;
        return benefits.find((benefit) => {
          const isFree = monthlyFee <= Number(benefit.value);
          const isTrial = benefit.isMembershipTrial;
          return isFree && !isTrial;
        });
      }
    );
  }

  private selectFreeTrialInEffect(): Selector<AppState, boolean> {
    return createSelector(
      this.selectFreeTrialBenefit(),
      this.selectSponsoredFreeMembershipBenefit(),
      (freeTrial, sponsored) => Boolean(freeTrial && !sponsored)
    );
  }

  private selectDiscountedFeeAfterTrial(): Selector<AppState, number> {
    return createSelector(
      this.selectMembership(),
      this.selectNonTrialMemberBenefits(),
      (membership, benefits) => {
        if (membership) {
          const reducer = (accumulator, current) => accumulator - current;
          const cost = benefits
            .map((benefit) => Number(benefit.value))
            .reduce(reducer, Number(membership.fee));
          return Math.max(cost, 0);
        }
        return 0;
      }
    );
  }

  private selectTotolMonthlyDiscounts(): Selector<AppState, number> {
    return createSelector(
      this.selectMonthlyTier(),
      this.selectNonTrialMemberBenefits(),
      (monthly, benefits) => {
        const reducer = (accumulator, current) => accumulator + current;
        const discount = benefits.map((benefit) => Number(benefit.value)).reduce(reducer, 0);
        const fee = monthly ? monthly.fee : 0;
        return Math.min(discount, fee);
      }
    );
  }

  private selectMembershipSponsors(): Selector<AppState, string[]> {
    return createSelector(this.selectNonTrialMemberBenefits(), (benefits) =>
      benefits.map((benefit) => benefit.sourceUserName)
    );
  }

  private selectedDiscountedMonthlyTier(): Selector<AppState, number> {
    return createSelector(
      this.selectMonthlyTier(),
      this.selectNonTrialMemberBenefits(),
      (monthly, benefits) => {
        const reducer = (accumulator, current) => accumulator - current;
        const cost = benefits
          .map((benefit) => Number(benefit.value))
          .reduce(reducer, Number(monthly?.fee));
        return Math.max(cost, 0);
      }
    );
  }

  private selectDiscountedMonthlyFee(): Selector<AppState, number> {
    return createSelector(
      this.selectMembership(),
      this.selectDiscountedFeeAfterTrial(),
      (membership, discountedFee) =>
        membership?.term === MembershipTerm.Annual ? discountedFee / 12 : discountedFee
    );
  }

  private selectDiscountedAnnualFee(): Selector<AppState, number> {
    return createSelector(
      this.selectMembership(),
      this.selectDiscountedFeeAfterTrial(),
      (membership, discountedFee) =>
        membership?.term === MembershipTerm.Annual ? discountedFee : discountedFee * 12
    );
  }

  private selectEligibleForAnnual(): Selector<AppState, boolean> {
    return createSelector(
      this.selectMembership(),
      this.selectNonTrialMemberBenefits(),
      (membership, benefits) => {
        const monthlyMember = membership && membership.term === MembershipTerm.Monthly;
        const noMemberDiscounts = !benefits.length;
        return Boolean((!membership || monthlyMember) && noMemberDiscounts);
      }
    );
  }
}

// Utility
export enum CancellationReasons {
  Membership_Is_Expensive = 'Membership is too expensive',
  Gas_Is_Expensive = 'Gas is too expensive',
  Schedule_Doesnt_Work = `Schedule doesn't work for me`,
  Bad_Experience_With_Yoshi = 'Bad experience with Yoshi',
  Didnt_Know_Yoshi = `didn't know what yoshi was`,
  Not_In_Service_Area = `I'M not in a service area`,
  Pausing = 'Temporary Pause',
  Other = 'Another reason',
}

const CancellationReasonsMap: { [key in CancellationReasons]: string } = {
  [CancellationReasons.Membership_Is_Expensive]: 'membership_expensive',
  [CancellationReasons.Gas_Is_Expensive]: 'gas_expensive',
  [CancellationReasons.Schedule_Doesnt_Work]: 'erratic_schedule',
  [CancellationReasons.Bad_Experience_With_Yoshi]: 'bad_experience',
  [CancellationReasons.Didnt_Know_Yoshi]: 'unfamiliar_with_service',
  [CancellationReasons.Not_In_Service_Area]: 'out_of_service_area',
  [CancellationReasons.Pausing]: 'pausing',
  [CancellationReasons.Other]: 'other',
};

/**
 * DEPRECATED! We should use new selector-based observables to access data
 */
export class MembershipInfo {
  readonly subscription: Nullable<MemberSubscription>;
  readonly benefits: Benefit[];

  readonly discountedFee: number;
  readonly discountedMonthlyFee: number;
  readonly discountedAnnualFee: number;
  readonly availableAnnualUpgrade: Nullable<MemberTier>;

  constructor(
    subscription: Nullable<MemberSubscription>,
    benefits: Benefit[],
    annualTier: Nullable<MemberTier>
  ) {
    this.subscription = subscription;
    this.benefits = benefits || [];

    if (subscription) {
      this.discountedFee = this.calculateDiscountedFee(subscription, benefits);
      this.discountedMonthlyFee = this.calculateMonthlyFee(subscription, this.discountedFee);
      this.discountedAnnualFee = this.calculateAnnualFee(subscription, this.discountedFee);
      this.availableAnnualUpgrade = this.isEligibleForUpgradeToAnnual() ? annualTier : null;
    }
  }

  private calculateDiscountedFee(membership: MemberSubscription, benefits: Benefit[]) {
    const reducer = (accumulator, current) => accumulator - current;
    const cost = benefits
      .map((benefit) => Number(benefit.value))
      .reduce(reducer, Number(membership.fee));
    return cost < 0 ? 0 : cost;
  }

  private calculateMonthlyFee(membership: MemberSubscription, discountedFee) {
    return membership.term === 'annual' ? discountedFee / 12 : discountedFee;
  }

  private calculateAnnualFee(membership: MemberSubscription, discountedFee) {
    return membership.term === 'annual' ? discountedFee : discountedFee * 12;
  }

  private isEligibleForUpgradeToAnnual() {
    const monthlyMember = this.subscription?.term === MembershipTerm.Monthly;
    const fullPriceMember = this.discountedMonthlyFee === 20;
    return monthlyMember && fullPriceMember;
  }
}
